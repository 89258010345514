import { configureStore } from "@reduxjs/toolkit";
import type { TypedUseSelectorHook } from "react-redux";
import {
  useDispatch as RDuseDispatch,
  useSelector as RDuseSelector,
} from "react-redux";
import ujian from "./reducers/ujian";
import user from "./reducers/user";

const store = configureStore({
  reducer: {
    ujian,
    user,
  },
});

type Store = ReturnType<typeof store.getState>;

export const useDispatch: () => typeof store.dispatch = RDuseDispatch;
export const useSelector: TypedUseSelectorHook<Store> = RDuseSelector;

export const getStore = store.getState;
export const dispatch = store.dispatch;
export default store;
