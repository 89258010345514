import { IDetailSatuanKerja } from "@api/satuanKerja";
import { IDetailSatuanWilayah } from "@api/satuanWilayah";
import { createSlice } from "@reduxjs/toolkit";
import {
  LocalStorageKey,
  removeLocalStorage,
  saveLocalStorage,
} from "@util/storage";

interface IJabatan {
  id: number;
  name: string;
  parent_id: number | null;
  satker_id: number;
}

interface IPangkat {
  golongan: string;
  id: number;
  kode: number;
  nama: string;
  singkatan: string;
}

interface IUserProfile {
  address: string;
  date_of_birth: string;
  id: number;
  jabatan_id: number;
  jenjang: string;
  nrp: string;
  pangkat_id: number;
  phone_number: string;
  place_of_birth: string;
  user_id: number;
  jabatan: IJabatan;
  pangkat: IPangkat;
}

interface IRoleUser {
  id: number;
  role_id: number;
  user_id: number;
  role: {
    id: number;
    name: string;
    description: string;
  };
}
export interface IUser {
  id: number | null;
  name: string;
  email: string;
  email_verified_at: string;
  satwil_id: number | null;
  satker_id: number | null;
  photo_id: string;
  access: string;
  is_superadmin: number | null;
  jabatan_id: number | null;
  place_of_birth: string | null;
  jabatan: string;
  profile: IUserProfile;
  satker: IDetailSatuanKerja;
  satwil: IDetailSatuanWilayah;
  role_permissions: Record<string, string>;
  role_user: IRoleUser;
}

export const initialState: IUser = {
  id: null,
  name: "",
  email: "",
  email_verified_at: "",
  satwil_id: null,
  satker_id: null,
  access: "",
  is_superadmin: null,
  jabatan_id: null,
  jabatan: "umum",
  photo_id: "",
  place_of_birth: "",
  satker: {
    id: 0,
    name: "",
    satwil_id: 0,
    description: "",
    latitude: "",
    longitude: "",
  },
  satwil: {
    name: "",
    description: "",
    latitude: "",
    longitude: "",
    type: "MABES",
  },
  profile: {
    address: "",
    date_of_birth: "",
    id: 0,
    jabatan_id: 0,
    jenjang: "",
    nrp: "",
    pangkat_id: 0,
    phone_number: "",
    user_id: 0,
    place_of_birth: "",
    jabatan: {
      id: 0,
      name: "",
      parent_id: null,
      satker_id: 0,
    },
    pangkat: {
      golongan: "",
      id: 0,
      kode: 0,
      nama: "",
      singkatan: "",
    },
  },
  role_permissions: {},
  role_user: {
    id: 0,
    role_id: 0,
    user_id: 0,
    role: {
      id: 0,
      name: "",
      description: "",
    },
  },
};

export const userSlice = createSlice({
  name: "notificationMsg",
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
      removeLocalStorage(LocalStorageKey.User);
    },
    update: (state, action) => {
      Object.assign(state, action.payload);
      saveLocalStorage(LocalStorageKey.User, state);
    },
  },
});

export const { reset, update } = userSlice.actions;

export default userSlice.reducer;
