import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./user";
import {
  LocalStorageKey,
  getLocalStorage,
  saveLocalStorage,
} from "@util/storage";

export type UjianType = "pilgan" | "uraian" | "essai";

type SoalJawaban = {
  nomor: number;
  soal_id: number;
  jawaban_id?: number;
  jawaban: string;
  bank_soal_id: number;
};

export type InitialUjianType = {
  currentSoal: { tipe: UjianType; soal: number };
  answered: {
    pilgan: SoalJawaban[];
    uraian: SoalJawaban[];
    essai: SoalJawaban[];
  };
  finish: boolean;
};

export const InitialUjian: InitialUjianType = {
  currentSoal: { tipe: "pilgan" as UjianType, soal: 1 },
  answered: {
    pilgan: [] as SoalJawaban[],
    uraian: [] as SoalJawaban[],
    essai: [] as SoalJawaban[],
  },
  finish: false,
};

export const ujianSlice = createSlice({
  name: "numbering",
  initialState: InitialUjian,
  reducers: {
    nextSoal: (state, action) => {
      if (action.payload === state.currentSoal.soal) {
        // if (state.currentSoal.tipe === "pilgan")
        //   state.currentSoal.tipe = "uraian";
        // if (state.currentSoal.tipe === "uraian")
        //   state.currentSoal.tipe = "essai";
        // if (state.currentSoal.tipe === "essai")
        //   state.currentSoal.tipe = "pilgan";
        state.currentSoal.soal = 1;
      } else {
        state.currentSoal.soal += 1;
      }
      window.history.pushState(null, "", "?s=" + state.currentSoal.soal);
    },
    prevSoal: (state, action) => {
      if (state.currentSoal.soal === 1) {
        // if (state.currentSoal.tipe === "pilgan")
        //   state.currentSoal.tipe = "essai";
        // if (state.currentSoal.tipe === "uraian")
        //   state.currentSoal.tipe = "pilgan";
        // if (state.currentSoal.tipe === "essai")
        //   state.currentSoal.tipe = "uraian";
        state.currentSoal.soal = action.payload;
      } else {
        state.currentSoal.soal -= 1;
      }
      window.history.pushState(null, "", "?s=" + state.currentSoal.soal);
    },
    clickSoal: (state, action) => {
      const { tipeSoal, selectedNumber } = action.payload;
      state.currentSoal.soal = selectedNumber;
      state.currentSoal.tipe = tipeSoal;
      window.history.pushState(null, "", "?s=" + selectedNumber);
    },
    chooseJawaban: (state, action) => {
      const {
        pathname,
        tipeSoal,
        nomor,
        soal_id,
        jawaban_id,
        jawaban,
        bank_soal_id,
      }: SoalJawaban & { tipeSoal: UjianType; pathname: string } =
        action.payload;
      let items = state.answered[tipeSoal];
      if (tipeSoal === "pilgan") {
        if (items.some((x) => x.soal_id === soal_id)) {
          items = items.map((i) =>
            i.soal_id === soal_id
              ? { nomor, soal_id, jawaban_id, bank_soal_id, jawaban }
              : i
          );
        } else {
          items = [
            ...items,
            { nomor, soal_id, jawaban_id, bank_soal_id, jawaban },
          ];
        }
      }
      if (tipeSoal === "essai" || tipeSoal === "uraian") {
        if (items.some((x) => x.soal_id === soal_id)) {
          items = items.map((i) =>
            i.soal_id === soal_id
              ? { nomor, soal_id, bank_soal_id, jawaban }
              : i
          );
        } else {
          items = [...items, { nomor, soal_id, bank_soal_id, jawaban }];
        }
      }
      state.answered[tipeSoal] = items;
      saveLocalStorage(LocalStorageKey.SavedUjian, {
        path: pathname,
        state,
      });
    },
    clearState: () => {
      return InitialUjian;
    },
    savedState: (state, action) => {
      const { path } = action.payload;
      const savedUjian = getLocalStorage(LocalStorageKey.SavedUjian);
      if (savedUjian && path === savedUjian.path) {
        return savedUjian.state;
      }
    },
  },
});

export const {
  nextSoal,
  prevSoal,
  clickSoal,
  chooseJawaban,
  clearState,
  savedState,
} = ujianSlice.actions;

export default ujianSlice.reducer;
