import { Modal as AntdModal, ModalProps as AntdModalProps } from "antd";
import CloseIcon from "@icon/close.svg";
import { Button } from "antd";
import { robotoFlexSemiBold } from "@util/font";
import { MouseEvent, useEffect, useState } from "react";

export interface ModalProps extends AntdModalProps {
  title: string;
  confirmText: string;
  canceledText: string;
  confirmLoading?: boolean;
  onCustomCancel?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const Modal = ({
  confirmText,
  canceledText,
  onCustomCancel,
  onOk,
  confirmLoading = false,
  ...props
}: ModalProps) => {
  const [footerButton, setFooterButton] = useState<React.ReactNode[]>([]);

  const confirmButton = () => (
    <Button
      key="submit"
      shape="round"
      className="h-14"
      type="primary"
      onClick={onOk}
      loading={confirmLoading}
    >
      <span className={robotoFlexSemiBold.className}>{confirmText}</span>
    </Button>
  );

  const cancelButton = () => (
    <Button
      key="back"
      shape="round"
      className="h-14"
      type={!confirmText ? "primary" : "default"}
      onClick={onCustomCancel || props.onCancel}
    >
      <span className={robotoFlexSemiBold.className}>{canceledText}</span>
    </Button>
  );

  useEffect(() => {
    let footer = [];

    if (canceledText) footer.push(cancelButton());
    if (confirmText) footer.push(confirmButton());
    setFooterButton(footer);
  }, [props.open]);
  return (
    <AntdModal
      closeIcon={
        <div style={{ marginRight: 1 }}>
          <CloseIcon />
        </div>
      }
      footer={footerButton}
      {...props}
    />
  );
};
