import { client } from "./global";

export enum LocalStorageKey {
  Token = "token",
  NavigateId = "navigateId",
  User = "user",
  CounterUjian = "counterUjian",
  SavedUjian = "savedUjian",
  ReadHanjar = "readHanjar",
}

export enum SessionStorageKey {
  DataUjian = "dataUjian",
  ResultUjian = "resultUjian",
  ReadHanjar = "readHanjar",
}

export const getLocalStorage = (key: LocalStorageKey) => {
  if (client) {
    const storage = localStorage.getItem(key);
    if (storage) return JSON.parse(storage);
    return "";
  }

  return "";
};

export const saveLocalStorage = (key: LocalStorageKey, data: any) => {
  if (client) {
    localStorage.setItem(key, JSON.stringify(data));
  }
};

export const removeLocalStorage = (key: LocalStorageKey) => {
  if (client) {
    localStorage.removeItem(key);
  }
};

export const getSessionStorage = (key: SessionStorageKey) => {
  if (client) {
    const storage = sessionStorage.getItem(key);
    if (storage) return JSON.parse(storage);
    return "";
  }

  return "";
};

export const saveSessionStorage = (key: SessionStorageKey, data: any) => {
  if (client) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }
};
