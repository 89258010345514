import axios, { AxiosRequestConfig } from "axios";
import { isObject, set } from "lodash";
import { deleteToken, getToken, saveToken } from "./auth";
import { LocalStorageKey } from "./storage";
import { getEnvironment } from "./environtment";
import { dispatch } from "@store/index";
import { reset, update } from "@store/reducers/user";

class API {
  constructor() {
    this.initialize();
  }

  initialize() {
    axios.defaults.baseURL = getEnvironment().api;

    axios.interceptors.request.use(
      (req) => {
        const url = req.url;
        const token = getToken();
        if (url && token) {
          req.headers.Authorization = "Bearer " + token.access_token;
        }
        return req;
      },
      (err) => Promise.reject(err)
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const statusCode = err.response.status;
        if (statusCode === 401) {
          if (!window.location.href.includes("login")) {
            localStorage.removeItem(LocalStorageKey.Token);
            return (window.location.href = "/login");
          }
        }
        return Promise.reject(err);
      }
    );
  }

  async authLogin(email: string, password: string) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/auth/login", { email, password })
        .then((result) => {
          resolve(result.data);
        })
        .catch((e) => reject(e));
    });
  }

  async authLoginOTP(token: string, otp: string) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/auth/verify-otp", { token, otp })
        .then((result) => {
          saveToken(result.data.data);
          resolve(result.data);
        })
        .catch((e) => reject(e));
    });
  }

  async resendOTP(token: string) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/auth/resend-otp", { token })
        .then((result) => {
          resolve(result.data);
        })
        .catch((e) => reject(e));
    });
  }

  async authLogout() {
    axios.post("/api/v1/auth/logout").then((result) => {
      deleteToken();
      dispatch(reset())
      return (window.location.href = "/login");
    });
  }

  async updateUser() {
    axios.get("/api/v1/auth/me").then((result) => {
      if (result.status) {
        dispatch(update(result.data.data));
      }
    });
  }

  get(url: string, config?: AxiosRequestConfig) {
    return axios.get(url, config);
  }

  post(
    url: string,
    data: Record<string, unknown> | string,
    config?: AxiosRequestConfig
  ) {
    return axios.post(url, data, config);
  }

  put(
    url: string,
    data: Record<string, unknown> | string,
    config?: AxiosRequestConfig
  ) {
    if (isObject(data)) {
      set(data, "_method", "PUT");
    }
    return axios.post(url, data, config);
  }

  delete(url: string, config?: AxiosRequestConfig) {
    return axios.delete(url, config);
  }
}

export const api = new API();
