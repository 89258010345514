import { Input as AntdInput, InputProps as AntdInputProps } from "antd";
import Password from "antd/es/input/Password";

interface InputProps extends AntdInputProps {
  title?: string;
  password?: boolean;
}

export const Input = ({
  password = false,
  title,
  className,
  ...props
}: InputProps) => {
  return (
    <div className={`flex flex-col w-full gap-1 ${className}`}>
      <span className={`font-roboto-flex text-content-black-100`}>{title}</span>
      {password ? (
        <Password {...props} size="large" />
      ) : (
        <AntdInput {...props} size="large" />
      )}
    </div>
  );
};
