import { robotoFlexSemiBold } from "@util/font";
import { Button as AntdButton, ButtonProps as AntdButtonProps } from "antd";

export enum ButtonType {
  primary = "primary",
  primaryOutline = "primaryOutline",
  clean = "default",
}

export interface ButtonProps extends AntdButtonProps {
  buttonType?: ButtonType;
}

export const Button = ({
  buttonType = ButtonType.primary,
  children,
  ...props
}: ButtonProps) => {
  return (
    <AntdButton block size="large" shape="round" {...props}>
      <span className={robotoFlexSemiBold.className}>{children}</span>
    </AntdButton>
  );
};
