export const convertToSlug = (text: string) => {
  const convert = text.toLowerCase().replaceAll(" ", "-");
  return convert;
};

export const capitalizeFirsWord = (text: string) => {
  if (!text) return "";
  const words = text.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }

  return words.join(" ");
};

export const alphabet = new Array(26)
  .fill(1)
  .map((_, i) => String.fromCharCode(65 + i));

export const numberWithSeparator = (
  value: string | number = 0,
  separator = "."
) => {
  return value.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const validateEmail = (email: string) => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return email.match(validRegex);
};
