import { Kanit, Roboto_Flex } from "next/font/google";

export const kanitBold = Kanit({
  subsets: ["latin"],
  weight: "700",
  variable: "--kanit-bold",
});

export const kanitMedium = Kanit({
  subsets: ["latin"],
  weight: "500",
  variable: "--kanit-medium",
});

export const kanit = Kanit({
  subsets: ["latin"],
  weight: "400",
  variable: "--kanit",
});

export const robotoFlex = Roboto_Flex({
  subsets: ["latin", "latin-ext"],
  weight: "400",
  variable: "--roboto-flex",
});

export const robotoFlexSemiBold = Roboto_Flex({
  subsets: ["latin", "latin-ext"],
  weight: "600",
  variable: "--roboto-flex-semi-bold",
});

export const robotoFlexBold = Roboto_Flex({
  subsets: ["latin", "latin-ext"],
  weight: "700",
  variable: "--roboto-flex-bold",
});

export const cssVariable = `${kanitBold.variable} ${kanitMedium.variable} ${kanit.variable} ${robotoFlex.variable} ${robotoFlexSemiBold.variable} ${robotoFlexBold.variable}`;
