// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { client } from "./global";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPg4sOSSPKSCnzCrJ3AeEhwy6ZdfTgEvo",
  authDomain: "e-learning-24663.firebaseapp.com",
  projectId: "e-learning-24663",
  storageBucket: "e-learning-24663.appspot.com",
  messagingSenderId: "1028602949902",
  appId: "1:1028602949902:web:516575a248ebac51c8675f",
  measurementId: "G-EJ670V3QS7",
};

// Initialize Firebase
const app = client ? initializeApp(firebaseConfig) : undefined;
const analytics = client ? getAnalytics(app) : null;

export const firebaseLogEvent = (event: string) => {
  if (client && analytics) logEvent(analytics, event);
};

export const FirebaseEventName = {
  Galeri: "galeri",
  Login: "login",
  Webinar: "webinar",
  Kompetensi: "kompetensi",
  Hanjar: "hanjar",
  UnggahPersyaratan: "unggah persyaratan",
  Profil: "profil",
  UbahPassword: "ubah password",
  Riset: "riset",
  UnggahRiset: "unggah riset",
};
