import { dispatch } from "@store/index";
import { update } from "@store/reducers/user";
import { Button } from "@ui/atom/Button";
import { api } from "@util/api";
import { Modal } from "antd";
import { InputOTP } from "antd-input-otp";
import { setCookie } from "cookies-next";
import { addMinutes, differenceInSeconds, intervalToDuration } from "date-fns";
import { useRouter } from "next/navigation";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

interface IModalOTPProps {
  formOTPState: [
    {
      token: string;
      otp: string;
    },
    Dispatch<
      SetStateAction<{
        token: string;
        otp: string;
      }>
    >
  ];
  formState: [
    {
      email: string;
      password: string;
    },
    Dispatch<
      SetStateAction<{
        email: string;
        password: string;
      }>
    >
  ];
  loadingState: [boolean, Dispatch<SetStateAction<boolean>>];
  isModalOpenState: [boolean, Dispatch<SetStateAction<boolean>>];
  showFailedLoginOTP: () => void;
  showFailedResendOTP: () => void;
}

let interval: any;
export const ModalOTP = (props: IModalOTPProps) => {
  const {
    formState,
    formOTPState,
    loadingState,
    isModalOpenState,
    showFailedLoginOTP,
    showFailedResendOTP,
  } = props;
  const [form, setForm] = formState;
  const [formOTP, setFormOTP] = formOTPState;
  const [loading, setLoading] = loadingState;
  const [isModalOpen, setIsModalOpen] = isModalOpenState;
  const [resendOTP, setResendOTP] = useState<any>();
  const [chance, setChance] = useState(1);
  const [loadingResendOTP, setLoadingResendOTP] = useState(false);
  const router = useRouter();
  const inputRef = useRef<any>([]);

  const close = useCallback(() => setIsModalOpen(false), []);

  const handleChangeOTP = useCallback((value: string[]) => {
    setFormOTP((prev) => ({ ...prev, otp: value.join("").replace(/\D+/g, '') }));
  }, []);

  const submitOTP = useCallback((value: string[]) => {
    const otp = value.join("");
    if (formOTP.token && otp) {
      setLoading(true);
      api
        .authLoginOTP(formOTP.token, otp)
        .then(async (result: any) => {
          if (result.status) {
            const data = await api
              .get("/api/v1/auth/me")
              .then((res) => res.data);
            if (data.status) {
              setIsModalOpen(false);
              dispatch(update(data.data));
              const isAdmin = data.data.access === "ADMINISTRATOR";
              if (isAdmin) {
                setCookie("client", "admin");
                router.push("/admin");
              } else {
                setCookie("client", "user");
                router.push("/");
              }
            }
          } else {
            setFormOTP((prev) => ({
              ...prev,
              otp: "",
            }));
          }
        })
        .catch(() => {
          showFailedLoginOTP();
          setFormOTP((prev) => ({
            ...prev,
            otp: "",
          }));
          setChance(chance + 1);
          setTimeout(() => inputRef.current[0].focus(), 100);
        })
        .finally(() => {
          setLoading(false);
          if (chance >= 3) {
            setForm((prev) => ({
              ...prev,
              password: "",
            }));
            setFormOTP({
              token: "",
              otp: "",
            });
            setChance(0);
            setIsModalOpen(false);
          }
        });
    }
  }, [formOTP, chance]);

  const submit = useCallback(() => {
    submitOTP(formOTP.otp.split(''));
  }, [formOTP]);

  const resend = useCallback(
    (e: any) => {
      e.preventDefault();
      if (formOTP.token) {
        setLoadingResendOTP(true);
        api
          .resendOTP(formOTP.token)
          .then((result: any) => {
            if (result.status) {
              const time = addMinutes(new Date(), 2);
              setResendOTP(time);
            }
          })
          .catch(() => {
            showFailedResendOTP();
          })
          .finally(() => {
            setLoadingResendOTP(false);
          });
      }
    },
    [formOTP]
  );

  useEffect(() => {
    if (isModalOpen && inputRef.current.length) {
      setTimeout(() => inputRef.current[0].focus(), 100);
    }
  }, [isModalOpen]);

  return (
    <Modal
      centered
      open={isModalOpen}
      closable={false}
      onCancel={close}
      footer={null}
    >
      <div className="flex flex-col items-center gap-6 mx-auto">
        <h1 className="font-bold text-2xl text-center">OTP</h1>
        <span>
          OTP telah dikirimkan melalui email: <b>{form.email}</b>
        </span>
        <div className="">
          <InputOTP
            name="otp"
            length={6}
            value={formOTP.otp.split("")}
            onChange={handleChangeOTP}
            autoSubmit={submitOTP}
            inputType="numeric"
            disabled={loading}
            inputClassName="font-bold text-4xl max-w-14"
            inputRef={inputRef}
          />
        </div>
        <Button
          type="primary"
          block={false}
          className="text-base"
          loading={loadingResendOTP}
          onClick={submit}
        >
          Submit
        </Button>
        <span>
          Tidak menerima OTP?{" "}
          {resendOTP ? (
            <RenderTimer
              resendOTP={resendOTP}
              isModalOpen={isModalOpen}
              setResendOTP={setResendOTP}
            />
          ) : (
            <Button
              type="link"
              block={false}
              className="p-0 text-base"
              loading={loadingResendOTP}
              onClick={resend}
            >
              Kirim ulang
            </Button>
          )}
        </span>
      </div>
    </Modal>
  );
};

const RenderTimer = ({ resendOTP, setResendOTP, isModalOpen }: any) => {
  const [counter, setCounter] = useState(0);

  const ticker = useCallback(() => {
    const now = new Date().getTime();
    const time = resendOTP?.getTime();
    if (now > time) {
      setCounter(0);
      setResendOTP(undefined);
    } else {
      setCounter(differenceInSeconds(now, time));
      interval = setTimeout(ticker, 1000);
    }
  }, [resendOTP]);

  useEffect(() => {
    if (isModalOpen) {
      if (!interval && resendOTP) {
        interval = setTimeout(ticker, 1000);
      }
    } else if (!isModalOpen && interval) {
      clearInterval(interval);
    }
  }, [isModalOpen, resendOTP]);

  const timer = useMemo(() => {
    const time = resendOTP;
    if (time) {
      const now = new Date();
      const d = intervalToDuration({
        start: now,
        end: time,
      });
      return `${("0" + (d.minutes || 0)).slice(-2)}:${(
        "0" + (d.seconds || 0)
      ).slice(-2)}`;
    }
    return "";
  }, [resendOTP, counter]);

  return <span className="font-bold">{timer}</span>;
};
