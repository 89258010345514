"use client";
import CloseIcon from "@icon/close.svg";
import { Button } from "@ui/atom/Button";
import { Input } from "@ui/atom/Input";
import { ModalForgotPassword } from "@ui/organism/ModalForgotPassword";
import { ModalOTP } from "@ui/organism/ModalOTP";
import { api } from "@util/api";
import { firebaseLogEvent } from "@util/firebase";
import { validateEmail } from "@util/string";
import { notification } from "antd";
import { ChangeEvent, useCallback, useEffect, useState } from "react";

export default function Home() {
  firebaseLogEvent("login");
  const [toast, contextHolder] = notification.useNotification();
  const loadingState = useState(false);
  const [loading, setLoading] = loadingState;
  const [disabled, setDisabled] = useState(true);
  const isModalOpenState = useState(false);
  const [_, setIsModalOpen] = isModalOpenState;
  const formState = useState({ email: "", password: "" });
  const [form, setForm] = formState;
  const formOTPState = useState({ token: "", otp: "" });
  const [__, setFormOTP] = formOTPState;
  const [openForgotPassword, setOpenForgotPassword] = useState(false);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    setForm((prev) => ({ ...prev, [name]: value }));
  }, []);

  const showFailedLogin = useCallback(() => {
    toast.error({
      message: "Gagal",
      description: `Email atau password salah`,
      placement: "bottomRight",
      closeIcon: (
        <div style={{ marginRight: 1 }}>
          <CloseIcon />
        </div>
      ),
      className: `toast-error`,
    });
  }, []);

  const showFailedLoginOTP = useCallback(() => {
    toast.error({
      message: "Gagal",
      description: `OTP salah`,
      placement: "bottomRight",
      closeIcon: (
        <div style={{ marginRight: 1 }}>
          <CloseIcon />
        </div>
      ),
      className: `toast-error`,
    });
  }, []);
  const showFailedResendOTP = useCallback(() => {
    toast.error({
      message: "Gagal",
      description: `Gagal mengirim OTP ke email: ${form.email}`,
      placement: "bottomRight",
      closeIcon: (
        <div style={{ marginRight: 1 }}>
          <CloseIcon />
        </div>
      ),
      className: `toast-error`,
    });
  }, [form]);

  const onSuccessForgotPassword = () => {
    toast.success({
      message: "Berhasil",
      description: `Permintaan ubah kata sandi telah dikirim ke email`,
      placement: "bottomRight",
      closeIcon: (
        <div style={{ marginRight: 1 }}>
          <CloseIcon />
        </div>
      ),
      className: `toast-success`,
    });
  };

  const onErrorForgotPassword = () => {
    toast.error({
      message: "Gagal",
      description: `Permintaan ubah kata sandi gagal`,
      placement: "bottomRight",
      closeIcon: (
        <div style={{ marginRight: 1 }}>
          <CloseIcon />
        </div>
      ),
      className: `toast-error`,
    });
  };

  const submit = useCallback(
    (e: any) => {
      e.preventDefault();
      if (form.email && form.password) {
        setLoading(true);
        api
          .authLogin(form.email, form.password)
          .then((result: any) => {
            if (result.status) {
              setFormOTP({
                token: result.data.access_token,
                otp: "",
              });
              setIsModalOpen(true);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            showFailedLogin();
            setForm((prev) => ({
              ...prev,
              password: "",
            }));
          });
      }
    },
    [form]
  );

  useEffect(() => {
    if (form.email && form.password) {
      if (validateEmail(form.email)) setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [form]);

  return (
    <>
      <span className={`font-kanit-bold text-content-black-100 text-4xl`}>
        Selamat Datang
      </span>
      <p className={`font-roboto-flex text-content-black-80 text-sm mb-6`}>
        Silahkan login untuk melanjutkan
      </p>
      <form onSubmit={submit}>
        <div className="flex flex-col gap-3 w-full mb-10">
          <Input
            name="email"
            title="Email"
            placeholder="contoh@mail.com"
            inputMode="email"
            onChange={handleChange}
          />
          <Input
            name="password"
            title="Password"
            password
            placeholder="8+ abjad & numerikal"
            onChange={handleChange}
          />
          <div
            role="button"
            className={`font-roboto-flex-bold text-primary place-self-end cursor-pointer`}
            onClick={() => setOpenForgotPassword(true)}
          >
            Lupa kata sandi
          </div>
        </div>

        <Button
          type="primary"
          className="h-14 mb-12"
          loading={loading}
          disabled={disabled}
          htmlType="submit"
        >
          Masuk
        </Button>
      </form>
      <span className={`font-roboto-flex text-xs text-content-black-40`}>
        © Copyright Humas POLRI ®  All Rights Reserved 
      </span>
      <ModalOTP
        formState={formState}
        formOTPState={formOTPState}
        loadingState={loadingState}
        isModalOpenState={isModalOpenState}
        showFailedLoginOTP={showFailedLoginOTP}
        showFailedResendOTP={showFailedResendOTP}
      />
      <ModalForgotPassword
        open={openForgotPassword}
        onClose={() => {
          setOpenForgotPassword(false);
        }}
        onSucces={onSuccessForgotPassword}
        onError={onErrorForgotPassword}
      />
      {contextHolder}
    </>
  );
}
