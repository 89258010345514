import { Button } from "@ui/atom/Button";
import { Input } from "@ui/atom/Input";
import { Modal, ModalProps } from "@ui/atom/Modal";
import { api } from "@util/api";
import { useEffect, useState } from "react";

interface ModalForgotPasswordProps {
  open: boolean;
  onClose: () => void;
  onSucces: () => void;
  onError: () => void;
}

export const ModalForgotPassword = ({
  open,
  onClose,
  onSucces,
  onError,
}: ModalForgotPasswordProps) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  const submitSend = () => {
    if (!email) return;
    api
      .post("/api/v1/auth/forgot-password", {
        email,
      })
      .then((response) => {
        if (response) {
          onClose();
          onSucces();
        }
      })
      .catch(() => {
        onError();
      })
      .finally(() => {
        setSubmit(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (submit) {
      setLoading(true);
      submitSend();
    }
  }, [submit]);

  return (
    <Modal
      title="Lupa kata sandi?"
      confirmText="Kirim"
      open={open}
      onCancel={onClose}
      onOk={() => setSubmit(true)}
      confirmLoading={loading}
      canceledText={""}
    >
      <div className="flex flex-col gap-4">
        <p className={`font-roboto-flex text-content-black-80 text-sm `}>
          Silahkan masukkan email
        </p>
        <div className="flex flex-col gap-1">
          <span className="text-sm">Email</span>
          <Input
            value={email}
            inputMode="email"
            placeholder="contoh@mail.com"
            onChange={(e) => {
              const value = e.target.value;
              setEmail(value);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
