enum Environment {
  Development = "development",
  Production = "production",
}

const helpers = {
  [Environment.Development]: {
    api: "https://api-dev-elearning.polisibaik.id",
    metaBase: {
      siteUrl: process.env.NEXT_PUBLIC_METABASE_SITE_URL || "",
      secretKey: process.env.NEXT_PUBLIC_METABASE_SECRET_KEY || "",
    },
  },
  [Environment.Production]: {
    api: "https://api-elearning.polisibaik.id",
    metaBase: {
      siteUrl: process.env.NEXT_PUBLIC_METABASE_SITE_URL || "",
      secretKey: process.env.NEXT_PUBLIC_METABASE_SECRET_KEY || "",
    },
  },
};

export const getEnvironment = () => {
  const key =
    (process.env.NEXT_PUBLIC_ENVIRONMENT as Environment) || "development";
  return helpers[key];
};
