import { setCookie } from "cookies-next";
import {
  LocalStorageKey,
  getLocalStorage,
  removeLocalStorage,
  saveLocalStorage,
} from "./storage";

type Token = {
  access_token: string;
  token_type: string;
  expires_in: number;
};

export const saveToken = (token: Token) => {
  saveLocalStorage(LocalStorageKey.Token, token);
  setCookie("token", token.access_token);
};

export const getToken = () => {
  return getLocalStorage(LocalStorageKey.Token);
};

export const deleteToken = () => {
  removeLocalStorage(LocalStorageKey.Token);
};
